export interface LowItem {
  // _id?: string;
  Title: string;
  Name?: string;
  Fixed?: number;
  IsPrimary: boolean;
  ItemIndex: number;
  ItemType?: 'Boolean' | 'Number' | 'Date' | 'String' | 'Strings';
  Info?: string;
  Selection?: string[]; // Auswahlmöglichkeiten bei Auswahlfeldern
  ExcelColIndex?: number;
  Value?: any;
}

export interface Item extends LowItem {
  [x: string]: any;
  _id: string;
}

export function compareItems(original: Item, toCompare: Item): boolean {
  let result = true;
  try {
    if (!original || !toCompare) return false;
    result =
      original.Title == toCompare.Title &&
      original.Name == toCompare.Name &&
      original.Fixed == toCompare.Fixed &&
      original.Name == toCompare.Name &&
      original.IsPrimary == toCompare.IsPrimary &&
      original.ItemIndex == toCompare.ItemIndex &&
      original.ItemType == toCompare.ItemType &&
      original.Info == toCompare.Info &&
      original.Selection == toCompare.Selection &&
      original.ExcelColIndex == toCompare.ExcelColIndex;

    if (!result) return false;
    if (original.Selection?.length !== toCompare.Selection?.length)
      return false;

    const origLength = original.Selection ? original.Selection.length : 0;
    for (let i = 0; i < origLength; i += 1) {
      if (
        original.Selection &&
        toCompare.Selection &&
        original.Selection[i] !== toCompare.Selection[i]
      )
        return false;
    }
  } catch (err) {
    result = false;
  }

  return result;
}

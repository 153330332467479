import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable, reduce, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cabinet, compareCabinets, LowCabinet } from '../models/Cabinet';
import { ImageEntry, ExtendFile, CabinetImg, BaseImage } from '../models/Image';
import { compareTemplate, Template } from '../models/Template';
import { Evaluation, compareEvaluations } from '../models/Evaluation';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { CheckObj } from '../views/risks/dialog-add-image/dialog-add-image.component';
import { AssessmentSyncService } from './assesmentsync.service';
// import { memoize } from './memoize';
// import { CacheModule, CACHE } from '@ngx-cache/core';
// import { BrowserCacheModule, MemoryCacheService } from '@ngx-cache/platform-browser';
// import { act } from '@ngrx/effects';

@Injectable({
  providedIn: 'root',
})
export class AssessmentStoreService {
  public isInSync$ = this.syncService.isInSync$;
  public isOnline$ = this.syncService.isOnline$;
  public syncFinished$ = this.syncService.syncFinished$;

  public static currentUserLocations = new BehaviorSubject<string[]>([]);
  public static currentCabinetSubject = new BehaviorSubject<Cabinet | null>(
    null,
  );
  public static currentCabinetsSubject = new BehaviorSubject<Cabinet[]>([]);
  public static currentEvaluationsSubject = new BehaviorSubject<Evaluation[]>(
    [],
  );
  public static currentEvaluationSubject =
    new BehaviorSubject<Evaluation | null>(null);
  public static currentTemplateSubject = new BehaviorSubject<Template | null>(
    null,
  );

  // private currentEvaluationId = '';
  // private currentCabinetId = '';

  private apiUrl = environment.riskApi;

  constructor(
    private offlineDb: NgxIndexedDBService,
    private http: HttpClient,
    private syncService: AssessmentSyncService,
  ) {
    this.listenToOnlineStatus();

    if (this.syncFinished$.value) {
      this.loadCabinets();
      this.loadTemplate();
    }

    this.syncFinished$.subscribe((finished) => {
      console.warn('syncFinished$.subscribe() finished:', finished);
      if (finished) {
        console.info(
          'Synchronisation is finished. Relaod Cabinets, Evaluations and the Template.',
        );
        this.loadCabinets();
        this.loadTemplate();
      }
    });
  }

  public clearOfflineData() {
    this.syncService.clearOfflineData();
  }

  listenToOnlineStatus(): void {
    console.info('listen to "online-status" for risk service');
    window.addEventListener('online', () => {
      console.log('switch to online-state');
      this.isOnline$.next(true);
    });
    window.addEventListener('offline', () => {
      console.log('switch to offline-state');
      this.isOnline$.next(false);
    });
  }

  // ------------------ Cabinet ------------------- //

  // ------------------ Cabinet.Anlage.Standorte -- //
  // ------------------ (for user rights) --------- //
  loadLocations(): Subject<string[]> {
    const result: Subject<string[]> = new Subject();
    if (this.isOnline$) {
      this.http
        .get<string[]>(`${this.apiUrl}/cabinets/locations/`)
        .subscribe((cabinetLocations) => {
          AssessmentStoreService.currentUserLocations.next(cabinetLocations);
          result.next(cabinetLocations);
        });
    }
    return result;
  }

  // ------------------ Cabinet (Multi) ----------- //

  loadCabinets(): Observable<boolean> {
    const result: Subject<boolean> = new Subject();
    console.log(`loadCabinets()`);
    const res1 = this.offlineDb.getAll<Cabinet>('Cabinet');
    const res2 = this.offlineDb.getAll<Cabinet>('Cabinet_created');
    const res = merge(res1, res2).pipe(reduce((a, b) => a.concat(b)));
    res.subscribe((offlineCabinets) => {
      AssessmentStoreService.currentCabinetsSubject.next(offlineCabinets);
      result.next(true);
      if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
        console.log(
          `GET /cabinets/all/ Offline:`,
          offlineCabinets ? offlineCabinets.length : 0,
        );
        this.http
          .get<Cabinet[]>(`${this.apiUrl}/cabinets/all/`)
          .subscribe((onlineCabinets) => {
            const deleted: string[] = offlineCabinets
              .filter((off) => !onlineCabinets.find((on) => off._id == on._id))
              .map((cabinet) => cabinet._id);
            const updated: Cabinet[] = onlineCabinets.filter(
              // new ones
              (on) => !offlineCabinets.find((off) => on._id == off._id),
            );
            onlineCabinets.forEach((onlineCabinet) => {
              // plus offlines which are different
              const offlineCabinet = offlineCabinets.find(
                (offline) => offline._id == onlineCabinet._id,
              );
              if (
                offlineCabinet !== undefined &&
                !compareCabinets(onlineCabinet, offlineCabinet)
              ) {
                // it exists offline, but it's different.
                updated.push(onlineCabinet);
              }
            });
            if (deleted.length > 0) {
              this.offlineDb.bulkDelete('Cabinet', deleted);
            }
            if (updated.length > 0) {
              this.offlineDb
                .bulkPut<Cabinet>('Cabinet', updated)
                .subscribe((offlineRes) => {
                  console.log(
                    `OFFLINE PATCH /cabinets/all/ => get all Cabinets Key:`,
                    offlineRes,
                  );
                });
            }
            if (deleted.length > 0 || updated.length > 0) {
              const r1 = this.offlineDb.getAll<Cabinet>('Cabinet');
              const r2 = this.offlineDb.getAll<Cabinet>('Cabinet_created');
              const resSum = merge(r1, r2).pipe(reduce((a, b) => a.concat(b)));
              resSum.subscribe((r) => {
                let id = '';
                if (AssessmentStoreService.currentCabinetSubject.value) {
                  id = AssessmentStoreService.currentCabinetSubject.value._id;
                } else {
                  id = r[0]._id;
                }
                this.loadEvaluationsByCabinet(id);
                AssessmentStoreService.currentCabinetsSubject.next(r);
                result.next(true);
              });
            } // else: online and offline is equal!
          });
      } else {
        const currentCabinetId = offlineCabinets[0]._id;
        this.loadEvaluationsByCabinet(currentCabinetId);
        AssessmentStoreService.currentCabinetsSubject.next(offlineCabinets);
        result.next(true);
      }
    });
    return result;
  }

  // ------------------ Cabinet (Single) ---------- //
  loadCabinet(id: string): Observable<boolean> {
    const result: Subject<boolean> = new Subject();
    console.log(`loadCabinet(${id})`);
    this.loadEvaluationsByCabinet(id);
    this.offlineDb.getByID('Cabinet', id).subscribe((c) => {
      if (!c) {
        this.offlineDb.getByID('Cabinet_created', id).subscribe((c1) => {
          AssessmentStoreService.currentCabinetSubject.next(c1 as Cabinet);
          result.next(true);
        });
      } else {
        AssessmentStoreService.currentCabinetSubject.next(c as Cabinet);
        result.next(true);
      }

      if (
        !AssessmentStoreService.currentCabinetSubject.value &&
        this.isOnline$.value &&
        !this.syncService.offlineUpdates()
      ) {
        console.log(`HTTP-request: GET /cabinets/${id}`);
        this.http
          .get<Cabinet>(`${this.apiUrl}/cabinets/${id}`)
          .subscribe((cabinet) => {
            AssessmentStoreService.currentCabinetSubject.next(cabinet);
            result.next(true);
            this.offlineDb.update<Cabinet>('Cabinet', cabinet);
          });
      }
    });
    return result;
  }

  postCabinet(cabinet: LowCabinet): Observable<Cabinet | null> {
    const result: Subject<Cabinet | null> = new Subject();
    console.log(`postCabinet()`, cabinet);
    if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
      console.log('POST /cabinets/ ');
      this.http
        .post<Cabinet>(`${this.apiUrl}/cabinets/`, cabinet)
        .subscribe((onlineCabinet) => {
          console.log(`OFFLINE sync (POST) cabinet:`, onlineCabinet);
          const obs = this.offlineDb.update<Cabinet>('Cabinet', onlineCabinet);
          obs.subscribe((res) => {
            if (res) this.loadEvaluationsByCabinet(res._id);
            AssessmentStoreService.currentCabinetSubject.next(res);
            result.next(res);
          });
        });
    } else {
      cabinet._id = this.createOfflineId();
      this.offlineDb.update('Cabinet_created', cabinet).subscribe((offline) => {
        let currentCabinetId = offline._id ? offline._id : '';
        console.log(`OFFLINE POST /cabinets/:${offline._id}/`);
        if (offline._id) currentCabinetId = offline._id;
        this.loadEvaluationsByCabinet(currentCabinetId);
        AssessmentStoreService.currentCabinetSubject.next(offline as Cabinet);
        result.next(offline as Cabinet);
      });
    }
    return result;
  }

  patchCabinet(cabinet: Cabinet): Observable<Cabinet | null> {
    const result: Subject<Cabinet | null> = new Subject();
    console.log(`patchCabinet(${cabinet._id})`);
    this.loadEvaluationsByCabinet(cabinet._id);
    if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
      console.log(`PATCH /cabinets/:${cabinet._id}`);
      this.http
        .patch<Cabinet>(`${this.apiUrl}/cabinets/${cabinet._id}`, cabinet)
        .subscribe((online) => {
          AssessmentStoreService.currentCabinetSubject.next(online);
          result.next(online);
          this.offlineDb.update<Cabinet>('Cabinet', online);
        });
    } else {
      try {
        if (cabinet._id.startsWith('offline')) {
          this.offlineDb
            .update('Cabinet_created', cabinet)
            .subscribe((offline) => {
              console.log(`PATCH /cabinets/:${offline._id} (update)`, offline);
              AssessmentStoreService.currentCabinetSubject.next(offline);
              result.next(offline);
            });
        } else {
          this.offlineDb.update('Cabinet', cabinet).subscribe((offline) => {
            console.log(`PATCH /cabinets/:${offline._id} (update)`, offline);
            this.offlineDb
              .getByID<Cabinet>('Cabinet', offline._id)
              .subscribe((offCab) => {
                console.log(`PATCH /cabinets/:${offCab._id} (getById)`, offCab);
                AssessmentStoreService.currentCabinetSubject.next(offCab);
                result.next(offCab);
              });
          });
          this.offlineDb
            .update('Cabinet_updated', { _id: cabinet._id })
            .subscribe((offline) => {
              console.log(
                `PATCH /cabinets/:${cabinet._id} Cabinet_updated added id:`,
                offline,
              );
            });
        }
      } catch (err) {
        console.error('PATCH /cabinets/:  ERROR:', err);
      }
    }
    return result;
  }

  deleteCabinet(id: string): Observable<Cabinet[]> {
    const result: Subject<Cabinet[]> = new Subject();
    console.log(`deleteCabinet(${id})`);
    this.offlineDb.delete('Cabinet', id).subscribe((deleted) => {
      console.log(`OFFLINE DELETE /cabinets/:${id}/`, deleted);
    });
    if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
      console.log(`DELETE /cabinets/:${id}`);
      this.http
        .delete<Cabinet[]>(`${this.apiUrl}/cabinets/${id}`)
        .subscribe((cabinets) => {
          this.offlineDb.clear('Cabinet');
          this.offlineDb.bulkPut<Cabinet>('Cabinet', cabinets);
          AssessmentStoreService.currentCabinetsSubject.next(cabinets);
          result.next(cabinets);
        });
    } else {
      if (id.startsWith('offline')) {
        this.offlineDb.delete('Cabinet_created', id).subscribe((del) => {
          console.log(`OFFLINE DELETE /cabinets/:${id}/ (new)`, del);
          const cabs1 = this.offlineDb.getAll<Cabinet>('Cabinet');
          const cabs2 = this.offlineDb.getAll<Cabinet>('Cabinet_created');
          merge(cabs1, cabs2).subscribe((c) => {
            AssessmentStoreService.currentCabinetsSubject.next(c);
            result.next(c);
          });
        });
      } else {
        this.offlineDb.delete('Cabinet', id).subscribe((deleted) => {
          console.log(`OFFLINE DELETE /cabinets/:${id}/ (existing)`, deleted);
          this.offlineDb
            .update('Cabinet_deleted', { _id: id })
            .subscribe((x) => {
              console.log('Cabinet_deleted', x);
            });
          const cabs1 = this.offlineDb.getAll<Cabinet>('Cabinet');
          const cabs2 = this.offlineDb.getAll<Cabinet>('Cabinet_created');
          merge(cabs1, cabs2).subscribe((c) => {
            AssessmentStoreService.currentCabinetsSubject.next(c);
            result.next(c);
          });
        });
      }
    }
    return result;
  }

  // -------------- Cabinet.Image ----------------- //
  // ------- Cabinet- & Evaluation images --------- //

  loadImage(imageId: string): Subject<any> {
    console.log(`loadImage(${imageId})`);
    let returnValue: ImageEntry | undefined = undefined;
    const result: Subject<any> = new Subject<any>();
    try {
      const res = this.offlineDb.getAll<ImageEntry>('Image');
      res.subscribe((imgEntries) => {
        console.log(
          `loadImage(${imageId}) count of offline images:`,
          imgEntries?.length,
        );
        returnValue = imgEntries.find((imgFile) => imgFile._id == imageId);
        if (returnValue) result.next(returnValue?.imgFile);
        else console.info('GET /images/ NO OFFLINE RESULT');
      });
      if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
        this.http
          .get(`${this.apiUrl}/images/${imageId}`)
          .subscribe((imgEntry) => {
            if (imgEntry) {
              this.offlineDb
                .update<ImageEntry>('Image', {
                  _id: imageId,
                  imgFile: imgEntry,
                } as ImageEntry)
                .subscribe((offlineRes) => {
                  if (offlineRes) result.next(offlineRes?.imgFile);
                  else console.info('GET /images/ didn`t write to offline-db');
                });
            } else {
              console.info('GET /images/ NO ONLINE RESULT');
            }
          });
      }
    } catch (err) {
      console.error(`loadImage(${imageId}) ERROR:`, err);
    }
    return result;
  }

  postCabinetImage(
    data: CheckObj,
    cabinet: Cabinet,
  ): Observable<Cabinet | null> {
    const result: Subject<Cabinet | null> = new Subject();
    console.log(`postCabinetImage((${data.file.name}, ${cabinet?._id})`);
    const imgFile = data.file as ExtendFile; // ???
    if (cabinet._id.startsWith('offline')) {
      console.error('POST /cabinets/images/ NOT POSSIBLE FOR offline-Cabinets');
      const virtualCabinet = {
        _id: '',
        Anlage: [],
        Uid: '',
      } as Cabinet;
      AssessmentStoreService.currentCabinetSubject.next(virtualCabinet);
      result.next(virtualCabinet);
      return result;
    }
    if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
      console.log(
        `POST /cabinets/images/ File(${imgFile.name}) Cabinet(${cabinet._id})`,
      );
      let resCabinet: Cabinet = cabinet;
      const formData = new FormData();
      formData.append('file', imgFile);
      formData.append('fileName', imgFile.name.replace('.', ''));
      formData.append('cabinet', JSON.stringify(cabinet));
      this.http
        .post(`${this.apiUrl}/cabinets/images/`, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe((event) => {
          if (event.type == HttpEventType.Response) {
            if (event.body !== null) {
              resCabinet = event.body as Cabinet;
              const onlineImg = resCabinet.Images?.find(
                (i) => i.Name == imgFile.name.replace('.', ''),
              );
              if (onlineImg) {
                imgFile.arrayBuffer().then((buffRes: any) => {
                  const base64Img = btoa(
                    String.fromCharCode(...new Uint8Array(buffRes)),
                  );
                  const b64 = base64Img.substring(0, 25);
                  console.log('[Online].postCabinetImage() base64:', b64);
                  this.offlineDb
                    .update('Image', {
                      _id: onlineImg._id,
                      imgFile: base64Img,
                    })
                    .subscribe((r) => {
                      console.log(
                        '[Online].postCabinetImage() offline-image?:',
                        r,
                      );
                      this.offlineDb
                        .update<Cabinet>('Cabinet', resCabinet)
                        .subscribe(() => {
                          AssessmentStoreService.currentCabinetSubject.next(
                            resCabinet,
                          );
                          result.next(resCabinet);
                          console.log(
                            'POST /cabinets/images/ result.next(resCabinet)',
                            resCabinet,
                          );
                        });
                    });
                });
              } else {
                console.error('[Online].postCabinetImage() image?', onlineImg);
              }
            } else {
              console.error('[Online].postCabinetImage() : NO body-content!!');
            }
          }
        });
    } else {
      let offlineId = this.createOfflineId();
      // first create offline-Image
      imgFile.arrayBuffer().then((buffer) => {
        if (buffer) {
          const base64Img = btoa(
            String.fromCharCode(...new Uint8Array(buffer)),
          );
          this.offlineDb
            .update('Cabinet_updated', {
              _id: offlineId,
              cabinetId: cabinet._id,
              imgFile: { data: base64Img },
              rawImage: imgFile,
            })
            .subscribe((r) => {
              console.warn('[Offline].postCabinetImage() offline-image?:', r);
              try {
                offlineId = (r as unknown as ImageEntry)?._id;
                console.warn(
                  '[Offline].postCabinetImage() offlineId:',
                  offlineId,
                );
                cabinet.Images?.push({
                  _id: offlineId,
                  Path: '',
                  InternalName: imgFile.name,
                  Name: imgFile.name,
                  Original: imgFile.name.split('.')[0],
                  Extension: imgFile.name.split('.').pop(),
                  Encoding: imgFile.encoding,
                  MIME: imgFile.mimetype,
                  Size: imgFile.size,
                  AsPreview: false,
                  Thumb: { data: new Uint8Array(buffer), type: 'Buffer' },
                } as CabinetImg);
              } catch (err) {
                console.error(`[Offline].postCabinetImage() ERROR:`, err);
              }
              // now update offline-Cabinet, which contains the new image
              this.offlineDb
                .update('Cabinet', cabinet)
                .subscribe((offlineResult) => {
                  console.log(
                    `[Offline].postCabinetImage() cabinet:${offlineResult._id}`,
                    cabinet,
                  );
                  AssessmentStoreService.currentCabinetSubject.next(
                    offlineResult,
                  );
                  result.next(offlineResult);
                });
              this.offlineDb.update('Cabinet_updated', {
                _id: cabinet._id,
                imgFile: { data: imgFile },
              });
            });
        }
      });
    }
    return result;
  }

  postEvaluationImage(
    data: CheckObj,
    evaluation: Evaluation,
  ): Observable<Evaluation> {
    const result: Subject<Evaluation> = new Subject();
    console.log(`postEvaluationImage(${data.file.name}, ${evaluation?._id})`);
    const imgFile = data.file as ExtendFile; // ???
    if (evaluation._id.startsWith('offline')) {
      console.error('POST /cabinets/images/ NOT POSSIBLE FOR offline-Cabinets');
      const virtualEvaluation = {
        _id: '',
        Name: '',
        createdAt: new Date(),
        Cabinet: undefined as unknown as Cabinet,
        updatedAt: undefined as unknown as Date,
        Page1: {
          Assesment: [],
        },
        Page2: {
          PruefItems: [],
          Reduktionsfaktor: '',
        },
        PDFResult: {
          requested: false,
          createdAt: undefined as unknown as Date,
          updatedAt: undefined as unknown as Date,
          File: '',
        },
      };
      AssessmentStoreService.currentEvaluationSubject.next(virtualEvaluation);
      result.next(virtualEvaluation);
      return result;
    }
    if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
      console.log(
        `POST /evaluations/images/ File(${data.file.name}) Cabinet(${evaluation._id})`,
      );
      let resEvaluation: Evaluation = evaluation;
      const formData = new FormData();
      formData.append('file', data.file);
      formData.append('fileName', data.file.name.replace('.', ''));
      formData.append('evaluation', JSON.stringify(evaluation));
      this.http
        .post(`${this.apiUrl}/evaluations/images/`, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe((event) => {
          if (event.type == HttpEventType.Response) {
            if (event.body !== null) {
              resEvaluation = event.body as Evaluation;
              const onlineImg = resEvaluation.Images?.find(
                (i) => i.Name == data.file.name.replace('.', ''),
              );
              console.log(
                '[Online].postEvaluationImage() online-image?:',
                onlineImg,
              );
              if (onlineImg) {
                imgFile.arrayBuffer().then((buffer: any) => {
                  const base64Img = btoa(
                    String.fromCharCode(...new Uint8Array(buffer)),
                  );
                  const b64 = base64Img.substring(0, 25);
                  console.log('[Online].postEvaluationImage() base64:', b64);
                  this.offlineDb
                    .update('Image', {
                      _id: onlineImg._id,
                      imgFile: base64Img,
                    })
                    .subscribe((r) => {
                      console.warn(
                        '[Online].postEvaluationImage() offline-image?:',
                        r,
                      );
                      this.offlineDb
                        .update<Evaluation>('Evaluation', resEvaluation)
                        .subscribe(() => {
                          AssessmentStoreService.currentEvaluationSubject.next(
                            resEvaluation,
                          );
                          result.next(resEvaluation);
                          console.log(
                            'Post /evaluations/images/ result.next(...)',
                            resEvaluation,
                          );
                        });
                    });
                });
              } else {
                console.error(
                  '[Online].postEvaluationImage() image?',
                  onlineImg,
                );
              }
            } else {
              console.error(
                '[Online].postEvaluationImage() : NO body-content!!',
              );
            }
          }
        });
    } else {
      let offlineId = this.createOfflineId();
      // first create offline-Image
      const imgFile = data.file as ExtendFile; // ???
      imgFile.arrayBuffer().then((buffer) => {
        if (buffer) {
          const base64Img = btoa(
            String.fromCharCode(...new Uint8Array(buffer)),
          );
          this.offlineDb
            .update('Evaluation_updated', {
              _id: offlineId,
              evaluationId: evaluation._id,
              imgFile: { data: base64Img },
              rawImage: imgFile,
            })
            .subscribe((r) => {
              console.warn('[Offline].postEvaluationImage() offline-image:', r);
              try {
                offlineId = (r as unknown as ImageEntry)?._id;
                console.warn(
                  '[Offline].postEvaluationImage() offlineId:',
                  offlineId,
                );
                evaluation.Images?.push({
                  _id: offlineId,
                  Path: '',
                  InternalName: imgFile.name,
                  Name: imgFile.name,
                  Original: imgFile.name.split('.')[0],
                  Extension: imgFile.name.split('.').pop(),
                  Encoding: imgFile.encoding,
                  MIME: imgFile.mimetype,
                  Size: imgFile.size,
                  Thumb: { data: new Uint8Array(buffer), type: 'Buffer' },
                } as BaseImage);
              } catch (err) {
                console.error(`[Offline].postEvaluationImage() ERROR:`, err);
              }
              // now update offline-Evaluation, which contains the new image
              this.offlineDb
                .update('Evaluation', evaluation)
                .subscribe((offline) => {
                  console.log(
                    `[Offline].postEvaluationImage() evaluations:${offline._id}`,
                  );
                  AssessmentStoreService.currentEvaluationSubject.next(offline);
                  result.next(offline);
                });
              this.offlineDb.update('Evaluation_updated', {
                _id: evaluation._id,
                imgFile: data.file,
              });
            });
        }
      });
    }
    return result;
  }

  /**
   * TODO : siehe delete EvaluationImage !!!
   * @param cabinetId the id of the cabinet as string
   * @param id of image as string
   * @returns Subject<Cabinet>
   */
  deleteCabinetImage(
    cabinetId: string,
    id: string,
  ): Observable<Cabinet | null> {
    const result: Subject<Cabinet | null> = new Subject();
    console.log(`DELETE /cabinets/images/${cabinetId}/${id}`);
    if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
      console.log(`deleteCabinetImage(${cabinetId}, ${id})`);
      this.http
        .delete<Cabinet>(`${this.apiUrl}/cabinets/images/${cabinetId}/${id}`)
        .subscribe((cabinet) => {
          AssessmentStoreService.currentCabinetSubject.next(cabinet);
          result.next(cabinet);
          this.offlineDb.update<Cabinet>('Cabinet', cabinet);
        });
    } else {
      this.offlineDb
        .getByID<Cabinet>('Cabinet', cabinetId)
        .subscribe((cabinet) => {
          cabinet;
          const img = cabinet.Images?.find((i) => i._id == id);
          if (img) {
            const index = cabinet.Images!.indexOf(img);
            if (index > -1) {
              cabinet.Images!.splice(index, 1); // 2nd parameter means remove one item only
              this.offlineDb.update<Cabinet>('Cabinet', cabinet);
              this.offlineDb.update<{ _id: string; imgId: string }>(
                'Image_deleted',
                { _id: cabinetId, imgId: id },
              );
              AssessmentStoreService.currentCabinetSubject.next(cabinet);
              result.next(cabinet);
            }
          }
        });
    }
    return result;
  }

  deleteEvaluationImage(
    evaluationId: string,
    id: string,
  ): Observable<Evaluation | null> {
    const result: Subject<Evaluation | null> = new Subject();
    console.log(`deleteEvaluationImage(${evaluationId}, ${id})`);
    if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
      if (evaluationId.startsWith('offline') || id.startsWith('offline')) {
        console.log('');
      }
      console.log(`DELETE /evaluations/images/${evaluationId}/${id}`);
      this.http
        .delete<Evaluation>(
          `${this.apiUrl}/evaluations/images/${evaluationId}/${id}`,
        )
        .subscribe((evaluation) => {
          AssessmentStoreService.currentEvaluationSubject.next(evaluation);
          this.offlineDb.update<Evaluation>('Evaluation', evaluation);
        });
    } else {
      if (evaluationId.startsWith('offline')) {
        console.error(
          'DELETE /evaluations/images/ NOT POSSIBLE FOR offline-Evaluations',
        );
        const virtualEvaluation = {
          _id: '',
          Name: '',
          Page1: { Assesment: [] },
          Page2: { PruefItems: [], Reduktionsfaktor: '' },
          Cabinet: { _id: '', Anlage: [], Uid: '' },
          createdAt: new Date(),
          updatedAt: new Date(),
          PDFResult: {
            requested: false,
            createdAt: new Date(),
            updatedAt: new Date(),
            File: '',
          },
        };
        AssessmentStoreService.currentEvaluationSubject.next(virtualEvaluation);
        result.next(virtualEvaluation);
        return result;
      }
      if (id.startsWith('offline')) {
        this.offlineDb
          .deleteByKey('Evaluation_updated', id)
          .subscribe((sussess) => {
            console.log(
              `deleteEvaluationImage() => Evaluation-Image was ${
                sussess ? 'successfuly' : 'NOT'
              } deleted`,
            );
            if (sussess) {
              this.offlineDb
                .getByID<Evaluation>('Evaluation', evaluationId)
                .subscribe((evaluation) => {
                  console.log(
                    `deleteEvaluationImage() => remove Image  from Evaluation [${evaluation?._id}] ... try to find it ... `,
                  );
                  const img = evaluation.Images?.find((i) => i._id == id);
                  if (img) {
                    const index = evaluation.Images!.indexOf(img);
                    console.log(
                      `deleteEvaluationImage() => found Evaluation.Images[index] ... try to remove it ... `,
                    );
                    if (index > -1) {
                      evaluation.Images!.splice(index, 1); // 2nd parameter means remove one item only
                      this.offlineDb
                        .update<Evaluation>('Evaluation', evaluation)
                        .subscribe((resEva) => {
                          console.log(
                            'DELETE /evaluations/images/ > reduced Evaluation:',
                            resEva,
                          );
                          AssessmentStoreService.currentEvaluationSubject.next(
                            resEva,
                          );
                          result.next(resEva);
                        });
                    }
                  }
                });
            }
          });
      } else {
        this.offlineDb.deleteByKey('Image', id).subscribe((sussess) => {
          console.log(
            `deleteEvaluationImage() => Evaluation-Image was ${
              sussess ? 'successfuly' : 'NOT'
            } deleted`,
          );
          if (sussess) {
            this.offlineDb
              .getByID<Evaluation>('Evaluation', evaluationId)
              .subscribe((evaluation) => {
                console.log(
                  `deleteEvaluationImage() => remove Image  from Evaluation [${evaluation?._id}] ... try to find it ... `,
                );
                const img = evaluation.Images?.find((i) => i._id == id);
                if (img) {
                  const index = evaluation.Images!.indexOf(img);
                  console.log(
                    `deleteEvaluationImage() => found Evaluation.Images[${index}] ... try to remove it ... `,
                  );
                  if (index > -1) {
                    evaluation.Images!.splice(index, 1); // 2nd parameter means remove one item only
                    this.offlineDb
                      .update<Evaluation>('Evaluation', evaluation)
                      .subscribe((resEva) => {
                        console.log(
                          'deleteEvaluationImage() => reduced Evaluation:',
                          resEva,
                        );
                        this.offlineDb
                          .update<{
                            _id: string;
                            imgId: string;
                          }>('Image_deleted', {
                            _id: evaluationId,
                            imgId: id,
                          })
                          .subscribe((d) =>
                            console.log(
                              `deleteEvaluationImage() => offline-update of EvaluationImage_deleted`,
                              d,
                            ),
                          );
                        AssessmentStoreService.currentEvaluationSubject.next(
                          resEva,
                        );
                        result.next(resEva);
                      });
                  }
                }
              });
          }
        });
      }
    }
    return result;
  }

  // --------------- Cabinet.ExcelFile ------------ //

  // nur in online-modus nutzen !
  postCabinetExcelFile(
    file: File,
    fileName: string,
    template: Template,
  ): Observable<Cabinet[]> {
    console.log(
      `postCabinetExcelFile(${file?.name}, ${fileName}, ${template?._id})`,
    );
    const result: Subject<Cabinet[]> = new Subject<Cabinet[]>();
    if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
      console.log('POST /cabinets/exelfiles/');
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', fileName);
      formData.append('template', JSON.stringify(template));
      this.http
        .post(`${this.apiUrl}/cabinets/exelfiles/`, formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe((event) => {
          if (event.type == HttpEventType.Response) {
            if (event.body !== null) {
              const cabinets = event.body as Cabinet[];
              this.offlineDb.bulkPut<Cabinet>('Cabinet', cabinets);
              AssessmentStoreService.currentCabinetsSubject.next(cabinets);
              result.next(cabinets);
            }
          }
        });
    } else {
      // TODO Offline ???
    }
    return result;
  }

  // ------ Evaluation (Cabinet.Ergebnis)---------- //
  loadNewEvaluationFor(
    cabinetId: string | null | undefined,
  ): Observable<Evaluation | null> {
    const result = new Subject<Evaluation | null>();
    const tempalteId = AssessmentStoreService.currentTemplateSubject.value?._id;
    console.log(`loadNewEvaluationsFor(${cabinetId}, ${tempalteId})`);
    if (cabinetId && tempalteId) {
      if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
        console.log(`online GET /evaluations/new/ `);
        this.http
          .get<Evaluation>(
            `${this.apiUrl}/evaluations/new/${cabinetId}/${tempalteId}`,
          )
          .subscribe((evaluation) => {
            AssessmentStoreService.currentEvaluationSubject.next(evaluation);
            result.next(evaluation);
            this.offlineDb
              .update<Evaluation>('Evaluation', evaluation)
              .subscribe((result) => {
                console.log(
                  `OFFLINE PATCH /evaluations/new/ => result:`,
                  result,
                );
              });
            console.log(`GET /evaluations/new/ => newId:`, evaluation._id);
            const tmpCab =
              AssessmentStoreService.currentEvaluationSubject.value?.Cabinet;
            if (tmpCab) {
              AssessmentStoreService.currentCabinetSubject.next(tmpCab);
              result.next(evaluation);
            }
          });
      } else {
        // offline
        console.log('OFFLINE GET /evaluations/new/ => : TODO');
        const cab = this.offlineDb.getByID<Cabinet>('Cabinet', cabinetId);
        const caC = this.offlineDb.getByID<Cabinet>(
          'Cabinet_created',
          cabinetId,
        );
        merge(cab, caC).subscribe((c) => {
          // wenn offline neu angelegt (gefunden)
          if (c) {
            this.offlineDb
              .getByID<Template>('Template', tempalteId) // neu erstellte Templates ????
              .subscribe((t) => {
                if (t) {
                  const evaluation = this.createEvaluatinFor(c, t);
                  AssessmentStoreService.currentEvaluationSubject.next(
                    evaluation,
                  );
                  result.next(evaluation);
                  this.offlineDb
                    .update('Evaluation_created', evaluation)
                    .subscribe((updated) => {
                      console.log(
                        'OFFLINE GET /evaluations/new/ => : evaluation',
                        updated,
                      );
                    }); // offlineEvaluation
                } else {
                  console.error(
                    'loadNewEvaluationsFor() Template is undefined!',
                  );
                  result.next(null);
                }
              });
            // wenn in 'Cabinet_created' NICHT gefunden, dann offline in 'Cabinet'
          } else {
            console.error(
              'loadNewEvaluationsFor() got Cabinet will Null-value!',
            );
            result.next(null);
          }
        });
      }
    } else {
      console.error(
        `GET /evaluations/new/:cabinetId/:tempalteId \n\tcabinetId should NOT be null!`,
      );
      result.next(null);
    }
    return result;
  }

  // loadEvaluations(): Subject<Evaluation[]> {
  //   console.log(`loadEvaluations()`);
  //   const res1 = this.offlineDb.getAll<Evaluation>('Evaluation');
  //   const res2 = this.offlineDb.getAll<Evaluation>('Evaluation_created');
  //   const res = merge(res1, res2).pipe(reduce((a, b) => a.concat(b)));
  //   res.subscribe((offEvaluations) => {
  //     if (AssessmentStoreService.currentCabinetSubject.value) {
  //       const id = AssessmentStoreService.currentCabinetSubject.value._id;
  //       const currentEvaluations = offEvaluations.filter((oE) => {
  //         oE.Cabinet._id === id;
  //       });
  //       AssessmentStoreService.currentEvaluationsSubject.next(
  //         currentEvaluations,
  //       );
  //     }
  //     AssessmentStoreService.currentEvaluationsSubject.next(offEvaluations);
  //     if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
  //       console.log(`GET /evaluations/all/ `);
  //       this.http
  //         .get<[Evaluation]>(`${this.apiUrl}/evaluations/all/`)
  //         .subscribe((onEvaluations) => {
  //           const deleted: string[] = offEvaluations
  //             .filter((off) => !onEvaluations.find((on) => off._id == on._id))
  //             .map((cabinet) => cabinet._id);
  //           const updated: Evaluation[] = onEvaluations.filter(
  //             (on) => !offEvaluations.find((off) => on._id == off._id),
  //           );
  //           onEvaluations.forEach((onEvaluation) => {
  //             const offEvaluation = offEvaluations.find(
  //               (e) => e._id == onEvaluation._id,
  //             );
  //             if (
  //               offEvaluation !== undefined &&
  //               !compareEvaluations(onEvaluation, offEvaluation)
  //             ) {
  //               updated.push(onEvaluation);
  //             }
  //           });
  //           if (deleted.length > 0)
  //             this.offlineDb.bulkDelete('Evaluation', deleted);
  //           if (updated.length > 0) {
  //             this.offlineDb
  //               .bulkPut<Evaluation>('Evaluation', updated)
  //               .subscribe((offlineRes) => {
  //                 console.log(
  //                   `OFFLINE PATCH /evaluations/all/ => get all Evaluations Key:`,
  //                   offlineRes,
  //                 );
  //               });
  //           }
  //           if (deleted.length > 0 || updated.length > 0) {
  //             const r1 = this.offlineDb.getAll<Evaluation>('Evaluation');
  //             const r2 =
  //               this.offlineDb.getAll<Evaluation>('Evaluation_created');
  //             const resSum = merge(r1, r2).pipe(reduce((a, b) => a.concat(b)));
  //             resSum.subscribe((r) => {
  //               if (AssessmentStoreService.currentCabinetSubject.value) {
  //                 const id =
  //                   AssessmentStoreService.currentCabinetSubject.value._id;
  //                 const currentCabs = r.filter((oE) => {
  //                   oE.Cabinet._id === id;
  //                 });
  //                 AssessmentStoreService.currentEvaluationsSubject.next(r);
  //               }
  //             });
  //           }
  //         });
  //     }
  //   });
  //   return AssessmentStoreService.currentEvaluationsSubject;
  // }

  loadEvaluation(id: string): Observable<boolean> {
    const result: Subject<boolean> = new Subject();
    console.log(`loadEvaluation(${id})`);
    this.offlineDb.getByID<Evaluation>('Evaluation', id).subscribe((e) => {
      if (!e) {
        this.offlineDb
          .getByID<Evaluation>('Evaluation_created', id)
          .subscribe((e1) => {
            AssessmentStoreService.currentEvaluationSubject.next(e1);
            result.next(true);
          });
      } else {
        AssessmentStoreService.currentEvaluationSubject.next(e);
        result.next(true);
      }
      if (AssessmentStoreService.currentEvaluationSubject.value) {
        const tmpCab =
          AssessmentStoreService.currentEvaluationSubject.value.Cabinet;
        const curCar = AssessmentStoreService.currentCabinetSubject.value;
        if (tmpCab && curCar && !compareCabinets(tmpCab, curCar)) {
          AssessmentStoreService.currentCabinetSubject.next(tmpCab);
        }
      } else if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
        console.log(`HTTP-request: GET /evaluations/${id}`);
        this.http
          .get<Evaluation>(`${this.apiUrl}/evaluations/${id}`)
          .subscribe((e) => {
            this.offlineDb.update<Evaluation>('Evaluation', e);
            AssessmentStoreService.currentEvaluationSubject.next(e);
            const tmpCab =
              AssessmentStoreService.currentEvaluationSubject.value?.Cabinet;
            const curCar = AssessmentStoreService.currentCabinetSubject.value;
            if (tmpCab && curCar && !compareCabinets(tmpCab, curCar)) {
              AssessmentStoreService.currentCabinetSubject.next(tmpCab);
            }
            result.next(true);
          });
      }
    });
    // if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
    //   console.log(`GET /evaluations/${id}`);
    //   this.http
    //     .get<Evaluation>(`${this.apiUrl}/evaluations/${id}`)
    //     .subscribe((e) => {
    //       this.offlineDb
    //         .update<Evaluation>('Evaluation', e)
    //         .subscribe((offlineRes) => {
    //           console.log(
    //             `OFFLINE PATCH /evaluations/${id} offline:`,
    //             offlineRes,
    //           );
    //         });
    //       AssessmentStoreService.currentEvaluationSubject.next(e);
    //       const tmpCab =
    //         AssessmentStoreService.currentEvaluationSubject.value?.Cabinet;
    //       if (tmpCab) {
    //         AssessmentStoreService.currentCabinetSubject.next(tmpCab);
    //       }
    //     });
    // }
    return result;
  }

  loadEvaluationsByCabinet(cabinetId: string): Observable<boolean> {
    const result: Subject<boolean> = new Subject();
    console.log(`loadEvaluationsByCabinet(${cabinetId})`);
    const res1 = this.offlineDb.getAll<Evaluation>('Evaluation');
    const res2 = this.offlineDb.getAll<Evaluation>('Evaluation_created');
    const res = merge(res1, res2).pipe(reduce((a, b) => a.concat(b)));
    res.subscribe((e) => {
      const evaluations = e.filter(
        (evaluation) => evaluation.Cabinet._id == cabinetId,
      );
      if (evaluations) {
        AssessmentStoreService.currentEvaluationsSubject.next(evaluations);
        if (
          AssessmentStoreService.currentEvaluationSubject.value &&
          AssessmentStoreService.currentEvaluationSubject.value._id.length > 0
        ) {
          const id = AssessmentStoreService.currentEvaluationSubject.value._id;
          const curEva = evaluations.find((e) => e._id == id);
          if (AssessmentStoreService.currentEvaluationSubject && curEva)
            AssessmentStoreService.currentEvaluationSubject.next(curEva);
          const tmpCab =
            AssessmentStoreService.currentEvaluationSubject.value?.Cabinet;
          if (tmpCab) {
            AssessmentStoreService.currentCabinetSubject.next(tmpCab);
          }
        }
        result.next(true);
      } else {
        console.error(
          'loadEvaluationsByCabinet() didn`t find any offline-data',
        );
      }
    });
    if (
      this.isOnline$.value &&
      cabinetId &&
      !this.syncService.offlineUpdates() &&
      !cabinetId?.startsWith('offline')
    ) {
      console.log(`HTTP-request: GET /evaluations/byCabinet/${cabinetId}`);
      this.http
        .get<Evaluation[]>(`${this.apiUrl}/evaluations/byCabinet/${cabinetId}`)
        .subscribe((evaluations) => {
          this.offlineDb
            .bulkPut<Evaluation>('Evaluation', evaluations)
            .subscribe((offlineRes) => {
              console.log(
                `OFFLINE PATCH /evaluations/byCabinet/${cabinetId} Key:`,
                offlineRes,
              );
            });
          if (
            AssessmentStoreService.currentEvaluationSubject.value &&
            AssessmentStoreService.currentEvaluationSubject.value._id.length > 0
          ) {
            const id =
              AssessmentStoreService.currentEvaluationSubject.value._id;
            const curEva = evaluations.find((e) => e._id == id);
            if (AssessmentStoreService.currentEvaluationSubject && curEva)
              AssessmentStoreService.currentEvaluationSubject.next(curEva);
            const tmpCab =
              AssessmentStoreService.currentEvaluationSubject.value?.Cabinet;
            if (tmpCab) {
              AssessmentStoreService.currentCabinetSubject.next(tmpCab);
            }
          }
          AssessmentStoreService.currentEvaluationsSubject.next(evaluations);
          result.next(true);
        });
    }
    return result;
  }

  patchEvaluation(evaluation: Evaluation): Observable<Evaluation | null> {
    const result: Subject<Evaluation | null> = new Subject<Evaluation | null>();
    console.log(`patchEvaluation(${evaluation._id})`);
    if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
      console.log(`PATCH /evaluations/${evaluation._id}`);
      this.http
        .patch<Evaluation>(
          `${this.apiUrl}/evaluations/${evaluation._id}`,
          evaluation,
        )
        .subscribe((httpEvaluation) => {
          console.log(`got result with Evaluation._id:`, httpEvaluation);
          this.offlineDb
            .update<Evaluation>('Evaluation', httpEvaluation)
            .subscribe((localEvaluations) => {
              console.log('OFFLINE PATCH /evaluations/:', localEvaluations);
            });
          AssessmentStoreService.currentEvaluationSubject.next(httpEvaluation);
          result.next(httpEvaluation);
          const tmpCab =
            AssessmentStoreService.currentEvaluationSubject.value?.Cabinet;
          if (tmpCab) {
            AssessmentStoreService.currentCabinetSubject.next(tmpCab);
          }
        });
    } else {
      if (evaluation._id.startsWith('offline')) {
        this.offlineDb
          .update('Evaluation_created', evaluation)
          .subscribe((offline) => {
            console.log(
              `[OFFLINE] PATCH /evaluations/:${offline._id}/ (created)`,
            );
            AssessmentStoreService.currentEvaluationSubject.next(
              offline as Evaluation,
            );
            result.next(offline as Evaluation);
            const tmpCab =
              AssessmentStoreService.currentEvaluationSubject.value?.Cabinet;
            if (tmpCab) {
              AssessmentStoreService.currentCabinetSubject.next(tmpCab);
            }
          });
      } else {
        this.offlineDb.update('Evaluation', evaluation).subscribe((offline) => {
          console.log(`OFFLINE PATCH /evaluations/:${offline._id}/ (existing)`);
          AssessmentStoreService.currentEvaluationSubject.next(
            offline as Evaluation,
          );
          result.next(offline as Evaluation);
          const tmpCab =
            AssessmentStoreService.currentEvaluationSubject.value?.Cabinet;
          if (tmpCab) {
            AssessmentStoreService.currentCabinetSubject.next(tmpCab);
          }
        });
        this.offlineDb.update('Evaluation_updated', { _id: evaluation._id });
      }
    }
    return result;
  }

  patchEvaluationImages(evaluation: Evaluation): Observable<Evaluation | null> {
    const result: Subject<Evaluation | null> = new Subject();
    console.log(`patchEvaluation(${evaluation._id})`);
    if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
      console.log(`PATCH /evaluations/${evaluation._id}`);
      this.http
        .patch<Evaluation>(
          `${this.apiUrl}/evaluations/images/${evaluation._id}`,
          evaluation,
        )
        .subscribe((httpEvaluation) => {
          console.log(`got result with Evaluation._id:`, httpEvaluation);
          this.offlineDb
            .update<Evaluation>('Evaluation', httpEvaluation)
            .subscribe((localEvaluations) => {
              console.log('OFFLINE PATCH /evaluations/:', localEvaluations);
            });
          AssessmentStoreService.currentEvaluationSubject.next(httpEvaluation);
          result.next(httpEvaluation);
        });
    } else {
      if (evaluation._id.startsWith('offline')) {
        this.offlineDb
          .update('Evaluation_created', evaluation)
          .subscribe((offline) => {
            console.log(
              `[OFFLINE] PATCH /evaluations/:${offline._id}/ (created)`,
            );
            AssessmentStoreService.currentEvaluationSubject.next(
              offline as Evaluation,
            );
            result.next(offline);
          });
      } else {
        this.offlineDb.update('Evaluation', evaluation).subscribe((offline) => {
          console.log(`OFFLINE PATCH /evaluations/:${offline._id}/ (existing)`);
          AssessmentStoreService.currentEvaluationSubject.next(
            offline as Evaluation,
          );
          result.next(offline);
        });
        this.offlineDb.update('Evaluation_updated', { _id: evaluation._id });
      }
    }
    return result;
  }

  deleteEvaluation(cabinetId: string, id: string): Observable<Evaluation[]> {
    const result: Subject<Evaluation[]> = new Subject();
    console.log(`deleteEvaluation(${id})`);
    if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
      console.log(`DELETE /evaluations/:${id}`);
      this.http
        .delete<Evaluation[]>(`${this.apiUrl}/evaluations/${id}`)
        .subscribe((evaluations) => {
          this.offlineDb.clear('Evaluation');
          this.offlineDb.bulkPut<Evaluation>('Evaluation', evaluations);
          const evaRes = evaluations.filter(
            (evaluation) => evaluation.Cabinet._id == cabinetId,
          );
          if (evaRes) {
            AssessmentStoreService.currentEvaluationsSubject.next(evaRes);
            result.next(evaRes);
          } else console.error('deleteEvaluation() didn`t find any data');
        });
    } else {
      if (id.startsWith('offline')) {
        this.offlineDb.delete('Evaluation_created', id).subscribe((deleted) => {
          console.log(`OFFLINE DELETE /evaluations/:${id}/ (new)`, deleted);
          const cabs1 = this.offlineDb.getAll<Evaluation>('Evaluation');
          const cabs2 = this.offlineDb.getAll<Evaluation>('Evaluation_created');
          merge(cabs1, cabs2).subscribe((e) => {
            const evaluations = e.filter(
              (evaluation) => evaluation.Cabinet._id == cabinetId,
            );
            if (evaluations) {
              AssessmentStoreService.currentEvaluationsSubject.next(
                evaluations,
              );
              result.next(evaluations);
            } else
              console.error('deleteEvaluation() didn`t find any offline-data');
          });
        });
      } else {
        this.offlineDb.delete('Evaluation', id).subscribe((del) => {
          console.log(`OFFLINE DELETE /evaluations/:${id}/ (existing)`, del);
          this.offlineDb
            .update('Evaluation_deleted', { _id: id })
            .subscribe(() => {
              const cabs1 = this.offlineDb.getAll<Evaluation>('Evaluation');
              const cabs2 =
                this.offlineDb.getAll<Evaluation>('Evaluation_created');
              merge(cabs1, cabs2).subscribe((e) => {
                const evaluations = e.filter(
                  (evaluation) => evaluation.Cabinet._id == cabinetId,
                );
                if (evaluations && evaluations.length > 0) {
                  AssessmentStoreService.currentEvaluationsSubject.next(
                    evaluations,
                  );
                  result.next(evaluations);
                  console.log(
                    `OFFLINE DELETE for [${cabinetId}] result:`,
                    evaluations,
                  );
                } else
                  console.error(
                    'deleteEvaluation() didn`t find any offline-data',
                  );
              });
            });
        });
      }
    }
    return result;
  }

  // TODO : ------------------ Offline-functions ----------

  // getPdfEvaluation(id: string): Observable<any> {
  //   console.log(`GET /pdfresult/:id${id}`);
  //   return this.http.get(`${this.apiUrl}/evaluations/pdfresult/${id}`);
  // }
  getPdfEvaluation(id: string): Observable<any> {
    // const result = this.http.get(`${this.apiUrl}/evaluations/pdfresult/${id}`, {
    //   responseType: 'blob',
    // });
    // result.subscribe((result) => {
    //   this.http.buildUrl(`${this.apiUrl}/evaluations/pdfresult/${id}`, result);
    // })
    return this.http.get(`${this.apiUrl}/evaluations/pdfresult/${id}`, {
      responseType: 'blob',
    });
    // return this.http.get(`${this.apiUrl}/evaluations/pdfresult/${id}`);
  }

  loadNewPdfFor(id: string): Observable<any> {
    console.log(`GET /pdfrequestfor/:id${id}`);
    return this.http.get(`${this.apiUrl}/evaluations/pdfrequestfor/${id}`, {
      responseType: 'blob',
    });
  }

  // kann möglicherweise raus? verwendet in: risks-tab-results.component.ts
  getPdfEvaluationsFor(cabinetId: string): Observable<any> {
    console.log(`GET /evaluations/pdfresults/${cabinetId}`);
    return this.http.get(`${this.apiUrl}/evaluations/pdfresults/${cabinetId}`);
  }

  // ------------- Template (edit/create)---------- //

  /**
   * Is loading the Template for the user
   * @returns Observable<Template>
   */
  loadTemplate(): Observable<boolean> {
    const result: Subject<boolean> = new Subject();
    this.offlineDb.getAll<Template>('Template').subscribe((templates) => {
      if (templates && templates.length > 1) {
        const actTemp = templates.reduce((a, b) => {
          if (a.createdAt > b.createdAt) return a;
          else return b;
        });
        console.log(`OFFLINE GET /templates/`, actTemp);
        if (actTemp) {
          AssessmentStoreService.currentTemplateSubject.next(actTemp);
          result.next(true);
        }
      } else if (templates && templates.length >= 1) {
        if (templates[0]) {
          AssessmentStoreService.currentTemplateSubject.next(templates[0]);
          result.next(true);
          // if (!compareTemplate(AssessmentStoreService.template, templates[0])) {
          //   // AssessmentStoreService.template = templates[0];
          //   AssessmentStoreService.currentTemplateSubject.next(templates[0]);
          // }
        }
      }
    });
    if (this.isOnline$.value && !this.syncService.offlineUpdates()) {
      console.log(`HTTP-request: GET /templates/`);
      this.http
        .get<Template[]>(`${this.apiUrl}/templates/all/`)
        .subscribe((templates) => {
          this.offlineDb
            .bulkPut<Template>('Template', templates)
            .subscribe(() => {
              const actTemp = templates.reduce((a, b) => {
                if (a.createdAt > b.createdAt) return a;
                else return b;
              });
              if (actTemp) {
                if (
                  !compareTemplate(
                    AssessmentStoreService.currentTemplateSubject.value,
                    actTemp,
                  )
                ) {
                  AssessmentStoreService.currentTemplateSubject.next(actTemp);
                  result.next(true);
                }
              }
            });
        });
    }
    return result;
  }

  patchTemplate(template: Template): Observable<Template | null> {
    const result: Subject<Template | null> = new Subject();
    console.log(`patchTemplate(${template._id})`, template);
    const idTemplate = template._id;
    if (this.isOnline$.value && !this.isInSync$.value) {
      console.log(`[ONLINE]PATCH /templates/:${idTemplate}`);
      this.http
        .patch<Template>(`${this.apiUrl}/templates/${idTemplate}`, template)
        .subscribe((t) => {
          this.offlineDb.update('Template', t).subscribe((offline) => {
            console.log('postTemplate() result from off-DB:', offline);
            if (offline) {
              AssessmentStoreService.currentTemplateSubject.next(
                offline as Template,
              );
              result.next(offline as Template);
            }
          });
        });
    } else {
      this.offlineDb.update('Template_updated', { id: idTemplate });
      this.offlineDb.update('Template', template).subscribe((offline) => {
        console.log('[OFFLINE]patchTemplate() for existing');
        if (offline) {
          AssessmentStoreService.currentTemplateSubject.next(
            offline as Template,
          );
          result.next(offline as Template);
          // AssessmentStoreService.template = offline as Template;
        }
      });
    }
    return result;
  }

  //  ---------------------   Utils  ---------------------
  createOfflineId(): string {
    return `offline_${new Date().getTime()}`;
  }

  createEvaluatinFor(cabinet: Cabinet, template: Template): Evaluation {
    const defaultEvaluation: Evaluation = {
      _id: this.createOfflineId(),
      Cabinet: cabinet,
      Template: template,
      Pruefung: [] as { ItemId: string; Value: object }[],
      Gefaehrdung: [] as { ItemId: string; Value: object }[],
      Page1: { Assesment: [] },
      Page2: {
        PruefItems: [],
        Reduktionsfaktor: '',
      },
      PDFResult: {
        createdAt: undefined,
        updatedAt: undefined,
        File: undefined,
      },
      Images: [],
      createdAt: new Date(),
      updatedAt: new Date(),
    } as unknown as Evaluation;
    const res = defaultEvaluation;
    template.Pruefung.forEach((itm) => {
      defaultEvaluation.Pruefung?.push({
        ItemId: itm._id,
        Value: {},
      });
    });
    template.Gefaehrdung.forEach((itm) => {
      defaultEvaluation.Gefaehrdung?.push({
        ItemId: itm._id,
        Value: {},
      });
    });
    return res;
  }
}

import { compareItems, Item } from './Item';

export interface Template extends LowTemplate {
  _id: string;

  Uid: string;

  createdAt: Date;
  updatedAt: Date;
}

export interface LowTemplate {
  _id?: string;
  Uid?: string;
  Anlage: Item[];
  Pruefung: Item[];
  Gefaehrdung: Item[];
  Category?: [
    {
      Category: string;
      Gefaehrdung: string;
      Nr: number;
    },
  ];
}

export function compareTemplate(
  original: Template | null,
  toCompare: Template | null,
): boolean {
  let result = true;
  if (!original) return false;
  if (!toCompare) return false;
  try {
    if (result && original._id !== toCompare._id) {
      result = false;
      console.warn(
        `compareCabinets() original._id[${original._id}] !== toCompare._id:[${toCompare._id}]`,
      );
    }
    if (result && original.Uid !== toCompare.Uid) {
      result = false;
      console.warn(
        `compareCabinets() original.Uid[${original.Uid}] !== toCompare.Uid:[${toCompare.Uid}]`,
      );
    }
    // original.Anlage.forEach((originalAnlageItem) => {
    for (const originalAnlageItem of original.Anlage) {
      const found = toCompare.Anlage.find(
        (itm) => itm._id == originalAnlageItem._id,
      );
      if (found) {
        if (!compareItems(originalAnlageItem, found)) {
          result = false;
          break;
        }
      } else {
        console.info(
          `Anlage-Item-Id:[${originalAnlageItem._id}] : Item not found => not equal!`,
        );
        result = false;
        break;
      }
    }
  } catch (err) {
    console.error('compareCabinets() ERROR:', err);
    result = false;
  }
  return result;
}

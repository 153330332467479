import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User } from 'src/app/models/User';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    'Load Users All': emptyProps(),

    'Load Users': emptyProps(),
    'Load Users Success': props<{ users: User[] }>(),
    'Load Users Failure': props<{ error: HttpErrorResponse }>(),

    'Load User': props<{ username: string }>(),
    'Load User Success': props<{ user: User }>(),

    'Delete User': props<{ id: string }>(),
    'Delete User Success': props<{ id: string }>(),

    'Update User': props<{
      id: string;
      blocked?: boolean;
      firstname?: string;
      lastname?: string;
      roles?: string[];
    }>(),
    'Update User Success': props<{ user: User }>(),

    'Invite User': props<{
      uid: string;
      xid: string;
      username: string;
      firstname: string;
      lastname: string;
      roles: string[];
    }>(),
    'Invite User Success': props<{ user: User }>(),

    'Patch Push Factory User Store': props<{
      user: string;
      factory: string;
    }>(),

    'Patch Pull Factory User Store': props<{
      user: string;
      factory: string;
    }>(),

    'Patch Push Risks User Locations': props<{
      user: string;
      location: string;
    }>(),

    'Patch Pull Risks User Locations': props<{
      user: string;
      location: string;
    }>(),
  },
});

import { CabinetImg } from './Image';

export interface Cabinet extends LowCabinet {
  _id: string;

  Anlage: {
    ItemId: string;
    Value: object;
  }[];
}

export interface LowCabinet {
  _id?: string;

  Uid: string;
  // Template?: any;

  Anlage?: {
    ItemId: string;
    Value: object;
  }[];

  Images?: CabinetImg[];
}

/**
 *
 * @param original the 'Cabinet'
 * @param toCompare the 'Cabinet' to caompare with.
 * @returns 'true' if the property-values are eques, otherwise 'false'
 */
export function compareCabinets(
  original: Cabinet,
  toCompare: Cabinet,
): boolean {
  let result = true;
  try {
    if (!original || !toCompare) return false;
    if (result && original._id !== toCompare._id) {
      result = false;
    }
    if (result && original.Uid !== toCompare.Uid) {
      result = false;
    }
    // original.Anlage.forEach((originalAnlageItem) => {
    if (original?.Anlage?.length > 0 && 
      toCompare.Anlage?.length > 0 ) {
      for (const originalAnlageItem of original?.Anlage) {
        const found = toCompare.Anlage.find(
          (itm) => itm.ItemId == originalAnlageItem.ItemId,
        );
        if (found) {
          if (result && originalAnlageItem.Value !== found.Value) {
            result = false;
            break;
          }
        } else {
          result = false;
          break;
        }
      }
    } else if (
      ((original?.Anlage?.length > 0 && toCompare.Anlage?.length == 0 )) ||
      ((original?.Anlage?.length == 0 && toCompare.Anlage?.length > 0 ))) {
        return false;
      }
    // original.Images?.forEach((originalImageItem) => {
    if (result && original.Images) {
      for (const originalImageItem of original.Images) {
        const found = toCompare.Images?.find(
          (itm) => itm._id == originalImageItem._id,
        );
        if (found) {
          const equal =
            originalImageItem.AsPreview == found.AsPreview &&
            originalImageItem.InternalName == found.InternalName &&
            originalImageItem.Path == found.Path &&
            originalImageItem.Name == found.Name &&
            originalImageItem.Original == found.Original &&
            originalImageItem.Extension == found.Extension &&
            originalImageItem.Encoding == found.Encoding &&
            originalImageItem.MIME == found.MIME &&
            originalImageItem.Size == found.Size &&
            originalImageItem.Thumb?.toString() == found.Thumb?.toString();
          if (result && !equal) {
            // console.info(
            //   `Image-Id:[${originalImageItem._id}] : original !== toCompare`,
            // );
            // console.log(
            //   `AsPreview [${originalImageItem.AsPreview}] == [${
            //     found.AsPreview
            //   }]\n
            //   InternalName [${originalImageItem.InternalName}] == [${
            //     found.InternalName
            //   }]\n
            //   Path [${originalImageItem.Path}] == [${found.Path}]\n
            //   Name [${originalImageItem.Name}] == [${found.Name}]\n
            //   Original [${originalImageItem.Original}] == [${found.Original}]\n
            //   Extension [${originalImageItem.Extension}] == [${
            //     found.Extension
            //   }]\n
            //   Encoding [${originalImageItem.Encoding}] == [${found.Encoding}]\n
            //   MIME [${originalImageItem.MIME}] == [${found.MIME}]\n
            //   Size [${originalImageItem.Size}] == [${found.Size}]\n
            //   Thumb [${originalImageItem.Thumb?.toString()} == [${found.Thumb?.toString()}]`,
            // );
            result = false;
            break;
          }
        } else {
          console.info(
            `Image-Id:[${originalImageItem._id}] : Item not found => not equal!`,
          );
          result = false;
          break;
        }
      }
    }
  } catch (err) {
    console.error(`compareCabinets() ${(err as Error).message}`, {
      Cabinets: [original, toCompare],
      Error: err,
    });
    result = false;
  }
  return result;
}
